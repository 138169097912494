import React from "react";
import Swiper from "react-id-swiper";

const Autoplay = () => {
    const params = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    };

    return (
        <Swiper {...params}>
            <div className="slide1 slide-content">
                <h4>Soluciones para plantas e ingeniería <br/> para todo tipo de plásticos en alta y baja densidad</h4>
            </div>
        </Swiper>
    );
};

export default Autoplay;

