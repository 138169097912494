import React from "react";
import {Link} from 'gatsby'

import Layout from "../components/Layout";
import data from "../../content/resources/products.json";
import Caroucel from "../components/Caroucel";

const IndexPage = () => (
        <Layout>
            <div id="main">
                <Caroucel/>
                <div className="inner">
                    <section id="about_us">
                        <div className="home">
                            <h1 className="home__title">¿Quiénes somos?</h1>
                        </div>

                        <div className="card">
                            <div className="card__content">
                                <h1 className="home__title_card">Zion Tech</h1>
                                <p className="home__parrafo_card">Zion Tech es una empresa de ingeniería pionera en
                                    tecnología y recursos integrados de la industria del poliéster reciclado.</p>
                                <Link to="/about" className="button">Más información</Link>
                            </div>
                        </div>
                    </section>
                    <section id="">
                        <div className="home">
                            <h1 className="home__title">En que nos diferenciamos</h1>
                        </div>
                        <div className="blog-card">
                            <div className="meta">
                                <div className="photo"
                                     style={{backgroundImage: 'url(https://images.pexels.com/photos/212286/pexels-photo-212286.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)'}}>

                                </div>
                            </div>
                            <div className="description">
                                <p className="home__parrafo_card">Zion Tech insiste en "Si no hay inovación no habrá
                                    futuro",
                                    la innovación tecnológica y las pruebas constante de Investigación y Desarrollo, son
                                    procesos primordiales para alcanzar el exito de ésta industria. Cuenta con más de 30
                                    ingenieros, compuestos por expertos y jóvenes, entrenados con vitalidad y actualizan sus
                                    conocimientos constantemente. Alcanzado más de 20 patentes y certificaciones, muestras
                                    una vez más de un espíritu de innovación tecnológica, y un laboratorio de materias
                                    primas, equipado con equipos avanzados de prueba para optimizar el proceso de reciclaje,
                                    hoy estamos dispuestos a brindar servicios a los clientes en soluciones técnicas.</p>
                            </div>
                        </div>
                    </section>

                    <section id="services">
                        <div className="home">
                            <h1 className="home__title">Productos</h1>
                        </div>
                        <section className="tiles">
                            {data.slice(0, 6).map((service) => {
                                return (
                                    <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                        <Link to={service.path} state={{service: service}}>
                                            <h2>{service.name}</h2>
                                            <div className="content">
                                                <p>
                                                    {service.description}
                                                </p>
                                            </div>
                                        </Link>
                                    </article>
                                )
                            })}
                            <div style={{flex: 1, paddingTop: 20, textAlign: 'center'}}>
                                <Link to="/products" className="button">Ver más productos</Link>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </Layout>
    )
;

export default IndexPage;
